<template>
    <article class="piece-article row no-gutters pb-3 mb-3 pt-2">
        <div class="col-md-4 col-lg-3 d-flex justify-content-center align-items-center pr-md-3">
            <img class="article-thumbnail w-100"
                 v-if="thumbnail"
                 v-bind:alt="title[$i18n.locale]"
                 v-bind:src="thumbnail">
        </div>
        <div class="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <h3 class="mt-0 mb-3 text-center text-md-left"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="100"
                v-html="title[$i18n.locale]">
            </h3>
            <p v-line-clamp:20="3"
               data-aos="fade-in"
               data-aos-duration="800"
               data-aos-delay="300">
                {{ $sanitize(content[$i18n.locale]) }}
            </p>
            <div class="row no-gutters">
                <div class="col-8"></div>
                <div class="col-4 text-center text-md-right">
                    <router-link class="btn btn-link"
                                 data-aos="fade-in"
                                 data-aos-duration="800"
                                 data-aos-delay="300"
                                 v-bind:to="to">
                        {{ $t("message.article.read-more") }}
                    </router-link>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "ArticleItem",
    props: {
        "article": {
            type: Object,
            required: true,
        },

        "to": {
            type: String,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        title () {
            return this.article?.title ?? "";
        },

        content () {
            return this.article?.content ?? "";
        },

        thumbnail () {
            return `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ this.article?.thumbnail ?? "" }`
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.article-thumbnail {

}
</style>
